#navbar {
    background: var(--nav-background);
    color: var(--nav-color);
    transition: .5s all;

    a {
        color: var(--nav-color);
        transition: .5s all;
    }

    //  --nav-color --nav-border
}
