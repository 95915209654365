@import "~bootstrap/scss/bootstrap";
@import"./variables";
@import "./responsive";


@import "system_form";
@import "system_nav";



body {
    background: var(--body-background);
    color: var(--body-color);
    transition: .5s all;
}

#app {
    background: var(--body-background);
    color: var(--body-color);
    transition: .5s all;

    a {
        color: var(--body-color);
        transition: .5s all;
    }
}

.page-title {
    margin: 25px 0 25px;
}

.cabecera {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .page-title {
        flex-grow: 2;
    }

    .filtro {
        display: flex;
        align-items: flex-end;
        margin-left: 10px;
    }
}

.btn-check:focus+.btn-secondary,
.btn-secondary:focus {
    box-shadow: 0 0 0 1px rgba(130, 138, 145, 0.5);
}

.modal-90w {
    width: 98%;
    max-width: 1000px;

    @include tablet {
        width: 90%;
    }
}

.btn-guardar {
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;

    svg {
        margin-left: 10px;
    }
}

.modal-advertencia {
    .modal-content {
        background: #fb8c00;
        color: #fff;

        i {
            color: #000;
        }

        .modal-header {
            border-bottom: solid 1px #8f6c02;
        }

        .modal-footer {
            border-top: solid 1px #8f6c02;
        }
    }
}

/* .table-responsive {
    max-height: calc(100vh - 225px);
} */

.table {
    tbody {
        display: block;
        height: calc(100vh - 245px);
        overflow: auto;
    }

    thead,
    tbody tr {
        display: table;
        width: 100%;
        table-layout: fixed;
        /* even columns width , fix width of table too*/
    }

    &.small {
        tbody {
            height: auto;
        }
    }
}

#sidebarMenu {
    a {
        display: flex;
        align-items: center;

        svg {
            margin-right: 10px;
        }
    }
}

.td-number {
    text-align: right;
}

.btn {
    border-radius: 0;
}

.dropdown-menu {
    max-height: 300px;
    overflow: auto;
}

.plus {
    position: fixed;
    bottom: 20px;
    right: 20px;
    border-radius: 100%;
    padding: 7px 11px 10px;
    line-height: 1;
}

.carga-masiva {
    position: fixed;
    bottom: 20px;
    right: 70px;
    border-radius: 100%;
    padding: 7px 11px 10px;
    line-height: 1;
}

.navbar-toggler:focus {
    box-shadow: none;
}

.modal-alert-warning {
    color: #664d03;
    background-color: #fff3cd;
}

form {
    max-width: 100%;
}


.pagination {
    margin-top: 7px;

    a {
        color: #000 !important;
    }
}

.table {
    transition: .5s all;

    tr,
    td,
    th {
        transition: .5s all;
    }
}

.modal-content {
    color: var(--modal-color);
}
