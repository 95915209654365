.form-floating {
    position: relative;

    .form-control,
    .form-select {
        border: 0;
        border-bottom: 1px solid #ced4da;
        border-radius: 0;
        padding: 1rem 0.75rem;
        background-color: transparent;

        &:focus {
            box-shadow: none;
            border-bottom: 1px solid #ced4da;
        }

        &.is-valid {
            background-image: none;
        }

        &.is-invalid {
            border-bottom: 1px solid $danger;
        }

        &:disabled,
        &[readonly] {
            background-color: transparent;
        }
    }

    &:after {
        position: absolute;
        display: block;
        content: "";
        width: 0;
        border-bottom: 1px solid $secondary;
        box-shadow: 0 0 4px 0 $secondary;
        transition: 0.3s all;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
    }

    &.is-invalid {
        &:after {
            border-bottom: 1px solid $danger;
            box-shadow: 0 0 4px 0 $danger;
        }
    }

    &:focus-within {
        &:after {
            width: 100%;
        }
    }

    &.focus {
        label {
            opacity: 0.65;
            transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
        }
    }

    &>.form-select {
        &~label {
            transform: scale(0.85) translateY(-1.3rem) translateX(0.15rem);
        }
    }
}

.input-group {
    position: relative;
    flex-wrap: nowrap;

    &:after {
        position: absolute;
        display: block;
        content: "";
        width: 0;
        border-bottom: 1px solid $secondary;
        box-shadow: 0 0 4px 0 $secondary;
        transition: 0.3s all;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
    }

    .dropdown-toggle {
        border: 0;
        border-bottom: 1px solid #ced4da;
        border-radius: 0;
        padding: 1rem 0.75rem;
        background: transparent;
        color: #212529;
        z-index: 0 !important;

        &.show {
            background: transparent;
            color: #212529;
        }
    }

    .form-floating {
        width: 100%;

        &:after {
            display: none;
        }
    }

    &:focus-within {
        &:after {
            width: 100%;
        }
    }
}

.invalid-feedback,
.invalid-tooltip {
    display: block;
}

.form-check-input {
    &:focus {
        box-shadow: none;
        border: 1px solid rgba(0, 0, 0, 0.25);
    }
}


.form-control {
    background: transparent !important;
    color: var(--input-color);

    &:focus {
        color: var(--input-color);
    }
}

.form-select {
    option {
        color: #212529;

        &:first-child {
            margin-top: -10px;
        }
    }
}


.modal-content {

    .form-control {
        background: transparent !important;
        color: var(--input-modal-color);

        &:focus {
            color: var(--input-modal-color);
        }
    }
}
