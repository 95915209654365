:root {
    --input-border: #6c757d;
    --input-border-active: #495057;
    --input-color: #212529;

    --input-modal-border: #6c757d;
    --input-modal-border-active: #e9ecef;
    --input-modal-color: #ffffff;

    --body-background: #f8fafc;
    --body-color: #212529;

    --modal-color: #f8f9fa;

    --nav-background: #e9ecef;
    --nav-color: #212529;
    --nav-border: #6c757d;

    --client-card-color: #ffffff;
    --client-card-background: gray;
    --client-card-dragging-background: rgb(165, 165, 165);
}


[data-theme="dark"] {
    --input-border: #6c757d;
    --input-border-active: #e9ecef;
    --input-color: #ffffff;

    --input-modal-border: #6c757d;
    --input-modal-border-active: #495057;
    --input-modal-color: #212529;


    --body-background: #282c34;
    --body-color: #f8f9fa;

    --modal-color: #212529;

    --nav-background: #212529;
    --nav-color: #ffffff;
    --nav-border: #dee2e6;

    --client-card-color: #ffffff;
    --client-card-background: gray;
    --client-card-dragging-background: rgb(165, 165, 165);
}
