$phone: 576px;
$tablet: 768px;
$notebook: 992px;
$desk: 1199px;
$tv: 1399px;

@mixin phone {
    @media screen and (min-width: $phone) and (max-width: $tablet) {
        @content;
    }
}
@mixin tablet {
    @media screen and (min-width: $tablet) {
        @content;
    }
}
@mixin note {
    @media screen and (min-width: $notebook) {
        @content;
    }
}
@mixin desk {
    @media screen and (min-width: $desk) {
        @content;
    }
}
@mixin tv {
    @media screen and (min-width: $tv) {
        @content;
    }
}
/* @mixin for-tablet-landscape-up {
  @media (min-width: 900px) { @content; }
}
@mixin for-desktop-up {
  @media (min-width: 1200px) { @content; }
}
@mixin for-big-desktop-up {
  @media (min-width: 1800px) { @content; }
} */
